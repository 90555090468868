import React from 'react';
import styled from 'styled-components';
import { graphql, useStaticQuery } from 'gatsby';
import { differenceInCalendarISOWeeks, parseISO } from 'date-fns';

const Progress = props => {
  return (
    <Container className={props.className}>
      <Text>
        Week {props.current} of {props.max}
      </Text>
      <Bar>
        <InnerBar
          css={{ width: `${Math.round((props.current / props.max) * 100)}%` }}
        />
      </Bar>
    </Container>
  );
};

const ProgressConnected = props => {
  const data = useStaticQuery(graphql`
    query {
      content: contentfulChallengesPage {
        endDate
        startDate
      }
    }
  `);

  const totalWeeks = differenceInCalendarISOWeeks(
    parseISO(data.content.endDate),
    parseISO(data.content.startDate)
  );
  const elapsedWeeks = Math.min(
    totalWeeks,
    Math.max(
      0,
      differenceInCalendarISOWeeks(new Date(), parseISO(data.content.startDate))
    )
  );

  return (
    <Progress
      className={props.className}
      current={elapsedWeeks}
      max={totalWeeks}
    />
  );
};

export default ProgressConnected;

const Container = styled.section`
  margin: 40px 0;
`;

const Text = styled.p`
  font-weight: bold;
  font-size: 17px;
`;

const Bar = styled.div`
  margin-top: 10px;
  height: 6px;
  border-radius: 3px;
  background: #eff5f7;
`;

const InnerBar = styled.div`
  float: left;
  height: 6px;
  border-radius: 3px;
  background: ${p => p.theme.color.accent};
`;
