import React from 'react';
import styled from 'styled-components';
import { graphql } from 'gatsby';
import Img from 'gatsby-image/withIEPolyfill';

import CTAButton from './CTAButton';

const Challenges = props => {
  return (
    <div className={props.className}>
      <Container>
        {props.challenges.nodes.map((challenge, index) => {
          return (
            <Tile key={index}>
              <Text>
                <Title>{challenge.title}</Title>
                <Tags>
                  {challenge.tags.map((tag, index) => {
                    return <Tag key={index}>{tag}</Tag>;
                  })}
                </Tags>
                <ImgMobileWrapper>
                  <Img
                    fluid={challenge.thumbnail.fluid}
                    alt={challenge.thumbnail.title}
                    objectFit="cover"
                  />
                </ImgMobileWrapper>
                <Intro>{challenge.intro.childMarkdownRemark.excerpt}</Intro>
                <CTAButton href={`/challenge/${challenge.slug}/`}>
                  More information
                </CTAButton>
              </Text>
              <ImgDesktopWrapper>
                <ImgDesktop
                  fluid={challenge.thumbnail.fluid}
                  alt={challenge.thumbnail.title}
                  objectFit="cover"
                />
              </ImgDesktopWrapper>
            </Tile>
          );
        })}
      </Container>
    </div>
  );
};

export default Challenges;

export const query = graphql`
  fragment ContentfulChallengeListView on ContentfulChallenge {
    title
    intro {
      childMarkdownRemark {
        excerpt(pruneLength: 1024)
      }
    }
    tags
    slug
    thumbnail {
      title
      fluid(maxWidth: 500, maxHeight: 500, quality: 90, cropFocus: CENTER) {
        ...GatsbyContentfulFluid
      }
    }
  }
`;

const Container = styled.section`
  ${p => p.theme.s} {
    margin: 40px -15px 80px;
  }
  ${p => p.theme.ml} {
    margin: 40px 0 80px;
  }
`;

const Tile = styled.div`
  background: #ffffff;
  box-shadow: 0px 2px 4px rgba(0, 43, 92, 0.15);
  border: 2px solid #eaaf0f;
  background-clip: padding-box;
  text-decoration: none;
  color: inherit;
  display: block;
  padding: 28px 24px;
  & + & {
    margin-top: 32px;
  }
  ${p => p.theme.s} {
    max-width: 480px;
    margin-left: auto;
    margin-right: auto;
  }
  ${p => p.theme.ml} {
    display: flex;
    align-items: center;
  }
`;

const ImgDesktopWrapper = styled.div`
  ${p => p.theme.s} {
    display: none;
  }
  ${p => p.theme.ml} {
    flex: 0 0 240px;
    align-self: stretch;
    margin: -14px -10px -14px 24px;
    border-radius: 4px;
    overflow: hidden;
  }
`;

const ImgDesktop = styled(Img)`
  ${p => p.theme.s} {
    display: none;
  }
  height: 100%;
`;

const ImgMobileWrapper = styled.div`
  ${p => p.theme.s} {
    border-radius: 4px;
    overflow: hidden;
    margin: 25px 0;
    height: 60vw;
    max-height: 320px;
  }
  ${p => p.theme.ml} {
    display: none;
  }
`;

const Text = styled.div`
  flex: 1;
`;

const Title = styled.h1`
  font-weight: bold;
  font-size: 24px;
  padding-bottom: 0.8em;
  margin-bottom: 0.8em;
  border-bottom: 2px solid #d9ebf3;
`;

const Tags = styled.div`
  display: flex;
  align-items: flex-end;
  flex-wrap: wrap;
  margin-bottom: -10px;
`;

const Tag = styled.div`
  height: 28px;
  background: #ffffff;
  border: 1px solid #d9ebf3;
  border-radius: 14px;
  padding: 0 12px;
  line-height: 30px;
  font-size: 15px;
  margin-bottom: 10px;
  margin-right: 10px;
`;

const Intro = styled.p`
  font-size: 20px;
  margin: 2em 0;
`;
