import React from 'react';
import { graphql, useStaticQuery } from 'gatsby';

import Layout from '../components/Layout/Layout';
import SEO from '../components/SEO';
import Hero from '../components/Hero';
import Wrap from '../components/Wrap';
import H1 from '../components/H1';
import Paragraph from '../components/Paragraph';
import Prompt from '../components/Prompt';
import Progress from '../components/Progress';
import Updates from '../components/Updates';
import Challenges from '../components/Challenges';
import SecondaryButtonAligned from '../components/SecondaryButtonAligned';
import useAuth from '../hooks/useAuth';

const ChallengesPage = () => {
  const [isAuth, setAuth] = useAuth();

  const data = useStaticQuery(graphql`
    query {
      content: contentfulChallengesPage {
        heroTitle1
        heroTitle2
        heroImage {
          fluid(maxWidth: 768, maxHeight: 512, cropFocus: CENTER, quality: 90) {
            ...GatsbyContentfulFluid
          }
        }
        introText {
          md: childMarkdownRemark {
            html
          }
        }
        challengesTitle
        updatesTitle
        allUpdatesButton
      }
      challenges: allContentfulChallenge(
        sort: { fields: createdAt, order: DESC }
      ) {
        nodes {
          ...ContentfulChallengeListView
        }
      }
      updates: allContentfulUpdate(
        sort: { order: DESC, fields: createdAt }
        limit: 2
      ) {
        nodes {
          ...ContentfulUpdateListView
        }
      }
    }
  `);

  return (
    <Layout>
      <SEO title="Challenges" />
      {isAuth ? (
        <>
          <Hero
            image={data.content.heroImage}
            title={[data.content.heroTitle1, data.content.heroTitle2]}
            titleIndent="second"
          />
          <Wrap>
            <H1 css={{ marginTop: 0 }}>{data.content.challengesTitle}</H1>
            <Paragraph
              dangerouslySetInnerHTML={{
                __html: data.content.introText.md.html,
              }}
            />
            <Progress />
            <Challenges challenges={data.challenges} />
            <H1>{data.content.updatesTitle}</H1>
            <Updates updates={data.updates} />
            <SecondaryButtonAligned href="/updates/">
              {data.content.allUpdatesButton}
            </SecondaryButtonAligned>
          </Wrap>
        </>
      ) : (
        <Prompt setAuth={setAuth} />
      )}
    </Layout>
  );
};

export default ChallengesPage;
