import React from 'react';
import styled from 'styled-components';

import SecondaryButton from './SecondaryButton';

const SecondaryButtonAligned = props => {
  return (
    <Wrapper className={props.className}>
      <SecondaryButton href={props.href} onClick={props.onClick}>
        {props.children}
      </SecondaryButton>
    </Wrapper>
  );
};

export default SecondaryButtonAligned;

const Wrapper = styled.div`
  text-align: center;
  margin: 30px 0 60px;
  ${p => p.theme.m} {
    margin: 40px 0 80px;
  }
  ${p => p.theme.l} {
    margin: 60px 0 120px;
  }
`;
