import React from 'react';
import { Link } from 'gatsby';
import styled from 'styled-components';

const SecondaryButton = props => {
  const ButtonComponent =
    props.href.startsWith('http://') ||
    props.href.startsWith('https://') ||
    props.href.startsWith('#')
      ? ButtonA
      : ButtonLink;

  return (
    <ButtonComponent
      className={props.className}
      to={props.href}
      href={props.href}
      onClick={props.onClick}
    >
      {props.children}
    </ButtonComponent>
  );
};

export default SecondaryButton;

const ButtonA = styled.a`
  display: inline-block;
  background: white;
  box-shadow: 0px 2px 3px rgba(0, 43, 92, 0.1);
  border: 1px solid #d9ebf3;
  font-weight: bold;
  color: inherit;
  text-decoration: none;

  height: 50px;
  line-height: 51px;
  border-radius: 25px;
  padding: 0 24px;
  font-size: 17px;
  ${p => p.theme.ml} {
    height: 70px;
    line-height: 72px;
    border-radius: 35px;
    padding: 0 32px;
    font-size: 20px;
  }

  :hover,
  :focus {
    color: ${p => p.theme.color.accent};
  }
  :active {
    opacity: 0.7;
    line-height: 74px;
  }
`;

const ButtonLink = ButtonA.withComponent(Link);
